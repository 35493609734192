// primary
@psychoYellow: #FFFF09;
@midnightBlack: #1D1D1B;
@frozenWhite: #FFFFFF;

// secondary
@toxicGreen: #6BFF36;
@slimeGreen: #E3FF09;

// functional
@flameOrange: #FF7300;
@redAlert: #FF2B5C;
@blazePink: #FF8FFC;
@ultraViolet: #7800DB;
@plasmaBlue: #7800DB;
@abyssBlue: #7800DB;

// ui variants
@psychoYellow2: #FFEB09;
@slimeGreen2: #E3F009;
@toxicGreen2: #4DE122;
@toxicGreen3: #2FC30E;
@midnightGray4: #EDEDE4;
@midnightGray3: #9C9C93;
@midnightGray2: #5B5B53;
@midnightGray1: #393935;


/*******************************
     User Global Variables
*******************************/

@primaryColor: @psychoYellow;
@pageBackground: @frozenWhite;

@secondaryColor : @toxicGreen;


/*******************************
        FONTs
*******************************/

@eslLegend: 'esl-legend';
@font-face {
  font-family: @eslLegend;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-decoration: inherit;
  text-transform: none;
  src: 
    url("../../../../assets/fonts/ESLLegend-Regular.woff") format('woff'),
    url("../../../../assets/fonts/ESLLegend-Regular.woff2") format('woff2');
}

@calpsSans: 'calps-sans';
@font-face {
  font-family: @calpsSans;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-decoration: inherit;
  text-transform: none;
  src: 
    url("../../../../assets/fonts/CalpsSans-Medium.otf") format('otf');
}

@pageFont @calpsSans;
@fontSize: 14px;
@fontSmoothing: antialiased;
