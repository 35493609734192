/*******************************
         Site Overrides
*******************************/

/*******************************
     User Global Variables
*******************************/

@primaryColor: @psychoYellow;
@pageBackground: @frozenWhite;

@secondaryColor : @toxicGreen;


/*******************************
        FONTs
*******************************/

@eslLegend: 'esl-legend';
@font-face {
  font-family: @eslLegend;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-decoration: inherit;
  text-transform: none;
  src: 
    url("../../../assets/fonts/ESLLegend-Regular.woff") format('woff'),
    url("../../../assets/fonts/ESLLegend-Regular.woff2") format('woff2');
}

@calpsSans: 'calps-sans';
@font-face {
  font-family: @calpsSans;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-decoration: inherit;
  text-transform: none;
  src: 
    url("../../../assets/fonts/CalpsSans-Medium.otf") format('otf');
}

@headerFont @eslLegend;
@pageFont @calpsSans;
@fontSize: 14px;
@fontSmoothing: antialiased;
